import CustomEventEmitter from '../../../shared/utils/CustomEventEmitter';

export type AddToCartEventEmitterType = ReturnType<typeof CustomEventEmitter>;

let addToCartEventEmitter: AddToCartEventEmitterType;

export const initAddToCartEventEmitter = () => {
  addToCartEventEmitter = CustomEventEmitter('addToCart');
};

export const getAddToCartEventEmitter = () => addToCartEventEmitter;

const displayAddToCartMessage = (productName: string, success: boolean) => {
  addToCartEventEmitter.emit('displayAddToCartMessage', productName, success);
};

export const onAddToCart = (
  handler: (sku: string) => Promise<void | boolean> | undefined,
) => {
  addToCartEventEmitter.on(
    'addToCart',
    async (sku: string, productName: string) => {
      const promiseResponse = handler(sku);
      if (!promiseResponse) {
        displayAddToCartMessage(productName, true);
        return;
      }

      try {
        const promiseResponseResolved = await promiseResponse;
        if (promiseResponseResolved === false) {
          throw new Error();
        }
        displayAddToCartMessage(productName, true);
      } catch {
        displayAddToCartMessage(productName, false);
      }
    },
  );
};
