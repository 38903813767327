import CrossEventEmitter from '../shared/utils/CrossEventEmitter';

type CrossTabsEvents = {
  'conversation-started': (uuid: string) => void;
  'set-current-operator': (uuid: string) => void;
  'last-seen-message-date-updated': (uuid: string) => void;
};

export const crossEventEmitter =
  CrossEventEmitter<CrossTabsEvents>('CrossTabsEvents');

export type CrossEventEmitterType = typeof crossEventEmitter;
