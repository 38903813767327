import { browsingTimeSpentOnPageTimer } from './browsingTimeSpentOnPageTimer';
import {
  activeTimeSpentOnPageTimer,
  initActivityEventHandlers,
} from './visitorActivityOnPage';

export default function initTimers(): void {
  browsingTimeSpentOnPageTimer.start();
  activeTimeSpentOnPageTimer.start();
  initActivityEventHandlers();
}
