import { buildHttpUrl } from '../buildUrl/getUrls';
import { VisitorContextConfigPayload } from '../types';
import { getStoredVisitorIdentity } from '../storedVisitorIdentity';
import { TargetingParams } from '../../light/types';
import { getTagConfig } from '../tagConfig';
import {
  getExecutedScoringRuleIds,
  setExecutedScoringRuleIds,
} from '../visitorState/executedScoringRuleIds';
import delayUntilDocumentIsVisible from '../../shared/utils/delayUntilDocumentIsVisible';
import { getJSCustomData } from './getJSCustomData';
import { VisitorContextConfig } from './types';
import { getInMemoryJwtCookie } from '../authentication/inMemoryJwtCookie';
import {
  getExecutedEngagementRules,
  setExecutedEngagementRules,
} from '../visitorState/executedEngagementRules';

export const abortVisitorContextCall = new AbortController();

const getVisitorContextConfigPayload = ({
  activeTimeSpentOnPage,
  browsingTimeSpentOnPage,
  customData = {},
  location = document.location.href,
  forceFullTagVersion = location.includes('idz-debug=true'),
  initialRequest,
  isAuthenticationEnabled,
  previousLocation = document.referrer,
  registerNavigation = true,
  sid,
  warnings,
}: TargetingParams & {
  activeTimeSpentOnPage: number;
  browsingTimeSpentOnPage: number;
  forceFullTagVersion?: boolean;
  initialRequest: boolean;
  isAuthenticationEnabled: boolean;
  registerNavigation?: boolean;
  sid: number;
  warnings: string[];
}): VisitorContextConfigPayload => {
  const { vuid } = isAuthenticationEnabled
    ? getInMemoryJwtCookie() || getStoredVisitorIdentity(sid)
    : getStoredVisitorIdentity(sid);
  const urlSearchParams = new URLSearchParams(document.location.search);
  const conversationId = urlSearchParams.get('idzconvid');
  if (registerNavigation) {
    setExecutedScoringRuleIds([]);
    setExecutedEngagementRules([]);
  }
  const { lang, language, tpl } = getTagConfig();
  return {
    sid,
    url: location,
    scoringPreviouslyExecutedRuleIds: getExecutedScoringRuleIds(),
    ...(conversationId !== null && { conversationId }),
    ...(forceFullTagVersion !== null && { forceFullTagVersion }),
    ...((lang || language) && { lang: lang || language }),
    ...(vuid !== null && { vuid }),
    ...(tpl && { tpl }),
    engagementPreviouslyExecutedRuleIds: getExecutedEngagementRules().map(
      (e) => e.engagementRuleId,
    ),
    activeTimeSpentOnPage,
    browsingTimeSpentOnPage,
    deviceHeight: window.innerHeight,
    deviceWidth: window.innerWidth,
    previousUrl: previousLocation,
    registerNavigation,
    customData,
    initialRequest,
    ...(warnings.length > 0 && { warnings: warnings.join(',') }),
  };
};

const getVisitorContextConfigFn = async <
  InitialRequest extends boolean = false,
>({
  activeTimeSpentOnPage = 0,
  browsingTimeSpentOnPage = 0,
  customData,
  forceFullTagVersion,
  initialRequest,
  isAuthenticationEnabled = false,
  location,
  previousLocation,
  registerNavigation,
  sid,
  warnings = [],
}: TargetingParams & {
  activeTimeSpentOnPage?: number;
  browsingTimeSpentOnPage?: number;
  forceFullTagVersion?: boolean;
  initialRequest: InitialRequest;
  isAuthenticationEnabled?: boolean;
  registerNavigation: boolean;
  sid: number;
  warnings?: string[];
}): Promise<VisitorContextConfig<InitialRequest>> => {
  const visitorContextUrl = buildHttpUrl(`api/website/${sid}/visitor-context`);
  const visitorContextPayload = getVisitorContextConfigPayload({
    activeTimeSpentOnPage,
    browsingTimeSpentOnPage,
    customData: registerNavigation
      ? { ...getJSCustomData(), ...customData }
      : { ...customData },
    forceFullTagVersion,
    initialRequest,
    isAuthenticationEnabled,
    location,
    previousLocation,
    registerNavigation,
    sid,
    warnings,
  });

  const response = await fetch(`${visitorContextUrl}`, {
    signal: abortVisitorContextCall?.signal,
    method: 'POST',
    body: JSON.stringify(visitorContextPayload),
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  });
  const visitorContextConfig: VisitorContextConfig<InitialRequest> =
    await response.json();
  return visitorContextConfig;
};

export const getVisitorContextConfig = <InitialRequest extends boolean = false>(
  args: Parameters<typeof getVisitorContextConfigFn<InitialRequest>>[0],
): Promise<VisitorContextConfig<InitialRequest>> =>
  delayUntilDocumentIsVisible(getVisitorContextConfigFn<InitialRequest>, args);
