/*____________________________________________________________________________________________*/
export default class Timer {
  private startTime: number | undefined;

  private pausedTime: number | undefined;

  private elapsedTime = 0;

  start(): void {
    if (this.startTime) {
      return;
    }

    this.startTime = Date.now();
    this.elapsedTime = 0;
  }

  pause(): void {
    if (!this.startTime) {
      return;
    }

    this.pausedTime = Date.now();
    this.elapsedTime += this.pausedTime - this.startTime;
  }

  resume(): void {
    if (!this.pausedTime) {
      return;
    }

    this.startTime = Date.now();
    this.pausedTime = undefined;
  }

  reset(): void {
    this.startTime = undefined;
    this.pausedTime = undefined;
    this.elapsedTime = 0;
  }

  get time() {
    if (!this.startTime) {
      return this.elapsedTime;
    }
    if (!this.pausedTime) {
      return Date.now() - this.startTime + this.elapsedTime;
    }
    return this.elapsedTime;
  }
}
