import {
  PublicGettableProperty,
  PublicListenableProperty,
  PublicOnEvents,
  PublicOnlyListenableProperty,
} from '../../../shared/types/publicMethods';
import getPublicProperty from '../getPublicEventFromKey';
import { getPublicPropertiesStore } from '../publicProperties/registerPublicProperties';

function off<Key extends PublicGettableProperty>(
  key: `${Key}Change`,
  callback: PublicOnEvents<Key>[Key],
): void;

function off<Key extends PublicOnlyListenableProperty>(
  key: Key,
  callback: PublicOnEvents<Key>[Key],
): void;

function off<Key extends PublicListenableProperty>(
  key: Key,
  callback: PublicOnEvents<Key>[Key],
): void {
  const property = getPublicProperty(key, callback);
  getPublicPropertiesStore().off(property, callback as PublicOnEvents[Key]);
}

export default off;
