/*______________________________________________________________________________________________________________________________________________________*/
const isDomainAllowed = (domain: string) =>
  /(iadvize\.com|onlinecontact\.uwv\.nl)\/?$/.test(domain);
/*_____________________________________________________________________________________________________*/
export const getScriptLink = (
  scripts: HTMLCollectionOf<HTMLScriptElement> = document.getElementsByTagName(
    'script',
  ),
): string | undefined => {
  if (!scripts || scripts.length < 1) {
    return;
  }

  //_________________________________________________________________________________________
  for (let i = 0; i < scripts.length; i += 1) {
    const dummyLink = document.createElement('a');
    dummyLink.href = scripts[i].src;

    if (
      isDomainAllowed(dummyLink.hostname) &&
      /(iadvize\.live\.js|iadvize\.js|chat_init\.js|chat_init\.php)$/.test(
        dummyLink.pathname,
      )
    ) {
      return dummyLink.href;
    }
  }
};

export const getLinkQueryParams = (): URLSearchParams => {
  const scriptLink = getScriptLink();
  if (!scriptLink) return new URLSearchParams();
  const url = new URL(scriptLink);
  return new URLSearchParams(url.search);
};
