import {
  PublicListenableProperty,
  PublicOnEvents,
  PublicOnlyListenableProperty,
} from '../../shared/types/publicMethods';
import {
  publicListenableProperties,
  publicOnlyListenableProperties,
} from './publicProperties';
import throwPublicError from './throwPublicError';

const SUFFIX = 'Change';

const getPublicEventFromKey = <Key extends PublicListenableProperty>(
  key: Key | `${Key}${typeof SUFFIX}`,
  callback: PublicOnEvents<Key>[Key],
): Key => {
  if (
    !publicOnlyListenableProperties.some(
      (publicProperty) =>
        publicProperty === (key as PublicOnlyListenableProperty),
    ) &&
    !publicListenableProperties.some(
      (publicProperty) => `${publicProperty}${SUFFIX}` === key,
    )
  ) {
    throwPublicError(`The "${key}" event does not exist`);
  }

  if (typeof callback !== 'function') {
    throwPublicError('A function must be provided as a second argument');
  }
  const property = key.replace(new RegExp(`${SUFFIX}$`, 'i'), '');
  return property as Key;
};

export default getPublicEventFromKey;
