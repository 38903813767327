import { buildHttpUrl } from '../buildUrl/getUrls';
import { StaticConfig } from '../types';
import { getTagConfig } from '../tagConfig';
import delayUntilDocumentIsVisible from '../../shared/utils/delayUntilDocumentIsVisible';

const getStaticConfigPayload = (sid: number, lang: string) => {
  const { tpl } = getTagConfig();
  return {
    sid: `${sid}`,
    lang,
    ...(tpl && { tpl }),
  };
};

const getStaticConfigFn = async (
  sid: number,
  language: string,
): Promise<StaticConfig> => {
  const liveUrl = buildHttpUrl('rpc/live.1.php');
  const staticConfigPayload = getStaticConfigPayload(sid, language);
  const urlPayload = new URLSearchParams(staticConfigPayload).toString();
  const staticConfigResponse = await fetch(`${liveUrl}?${urlPayload}`);
  const staticConfigData: StaticConfig = await staticConfigResponse.json();
  return staticConfigData;
};

export const getStaticConfig = (
  ...args: Parameters<typeof getStaticConfigFn>
): Promise<StaticConfig> =>
  delayUntilDocumentIsVisible(getStaticConfigFn, ...args);
