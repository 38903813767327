import { DEFAULT_TTL_S } from './constants';
import { CookieParams } from './types';

export const setCookie = ({
  domain,
  name,
  value,
  ttl = DEFAULT_TTL_S,
}: CookieParams) => {
  let cookie = `${name}=${value}; max-age=${ttl}; path=/; sameSite=None; Secure;`;
  if (domain) cookie += ` domain=.${domain};`;

  document.cookie = cookie;
};
