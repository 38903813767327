export default function delayUntilDocumentIsVisible<T extends unknown[], U>(
  fn: (...fnArgs: T) => Promise<U>,
  ...args: T
): Promise<U> {
  if (document.visibilityState === 'visible') {
    //______________________________________________________________
    return fn(...args);
  }
  return new Promise((resolve) => {
    const onVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        //____________________________________________________________________________
        document.removeEventListener('visibilitychange', onVisibilityChange);
        resolve(fn(...args));
      }
    };
    //_____________________________________________
    document.addEventListener('visibilitychange', onVisibilityChange);
  });
}
