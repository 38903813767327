/*______________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/

import CustomEventEmitter, { Dispatcher } from './CustomEventEmitter';

//________________________________________________________________
type CustomEventMap = Record<string, (...args: any[]) => void>;

const CROSS_EVENT_EMITTER_KEY = 'IADVIZE_CROSS_EVENT_EMITTER_KEY';

type CrossEventEmitterFn<CustomEvents extends CustomEventMap> = Pick<
  Dispatcher<CustomEvents>,
  'on' | 'off' | 'emit'
> & {
  uuid: string;
  isLocalEmitter: (uuid: string) => boolean;
};

export default function CrossEventEmitter<CustomEvents extends CustomEventMap>(
  namespace: string,
): CrossEventEmitterFn<CustomEvents> {
  const originalDispatcher = CustomEventEmitter<CustomEvents>(namespace);
  const getCrossEventKey = (type: string) =>
    `${CROSS_EVENT_EMITTER_KEY}_${namespace}_${type}`;
  const elm = window;
  const uuid = Math.random().toString();

  const listeners = new Map<Function, EventListener>();

  return {
    on: (type, fn) => {
      //___________________
      originalDispatcher.on(type, fn);
      //_________________________________
      const crossEventKey = getCrossEventKey(type as string);
      const storageEventListener = (({ key, newValue }: StorageEvent) => {
        if (key !== crossEventKey || newValue === null) {
          return;
        }
        const detail = JSON.parse(newValue).detail as Parameters<typeof fn>;
        fn(...detail);
      }) as EventListener;
      elm.addEventListener('storage', storageEventListener);
      listeners.set(fn, storageEventListener);
    },
    emit: (type, ...detail) => {
      //_________________
      originalDispatcher.emit(type, ...detail);
      //____________________________________
      const crossEventKey = getCrossEventKey(type as string);
      localStorage.setItem(crossEventKey, JSON.stringify({ detail }));
      localStorage.removeItem(crossEventKey);
    },
    off: (type, fn) => {
      //___________________
      originalDispatcher.off(type, fn);
      //____________________
      const storageEventListener = listeners.get(fn);
      if (!storageEventListener) {
        return;
      }
      elm.removeEventListener('storage', storageEventListener);
      listeners.delete(fn);
    },
    uuid,
    isLocalEmitter: (_uuid: string) => _uuid === uuid,
  };
}
