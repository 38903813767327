import { getTagConfig } from '../../entry/tagConfig';
import { delCookie } from './delCookie';
import { setCookie } from './setCookie';

const TOP_DOMAIN_TEST_COOKIE = 'iadvize_test_cookie_top_domain';

let cachedTopDomain: string | undefined;

export function getTopDomainCandidates(hostname: string): string[] {
  return hostname
    .split('.')
    .reverse()
    .reduce<string[]>(
      (candidates, hostnamePart, index) =>
        index === 0
          ? [hostnamePart]
          : [...candidates, `${hostnamePart}.${candidates[index - 1]}`],
      [],
    );
}

function checkTopDomainCandidate(candidate: string): boolean {
  setCookie({ name: TOP_DOMAIN_TEST_COOKIE, value: '', domain: candidate });
  return document.cookie.includes(TOP_DOMAIN_TEST_COOKIE);
}

export default function getTopDomain(): string | undefined {
  if (cachedTopDomain) return cachedTopDomain;

  const { allowedCookieDomains } = getTagConfig();

  const { hostname } = window.location;

  const candidates =
    allowedCookieDomains && allowedCookieDomains.length > 0
      ? allowedCookieDomains
      : getTopDomainCandidates(hostname);

  const topDomain = candidates.find(checkTopDomainCandidate);
  delCookie(TOP_DOMAIN_TEST_COOKIE, topDomain);
  cachedTopDomain = topDomain;
  return topDomain;
}
