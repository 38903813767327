import {
  PublicSettableProperty,
  PublicPropertiesState,
} from '../../../shared/types/publicMethods';
import { publicSettableProperties } from '../publicProperties';
import throwPublicError from '../throwPublicError';

type SetOptions = {
  'visitor:cookiesConsent': number;
  'visitor:GDPRConsent': null;
};

const setMap: Partial<
  Record<
    PublicSettableProperty,
    (
      value: PublicPropertiesState<PublicSettableProperty>[PublicSettableProperty],
      options?: SetOptions[PublicSettableProperty],
    ) => void
  >
> = {};

const set = <Key extends PublicSettableProperty>(
  key: Key,
  value: PublicPropertiesState<Key>[Key],
  options?: SetOptions[Key],
) => {
  if (!publicSettableProperties.includes(key as PublicSettableProperty)) {
    throwPublicError(`The "${key}" property does not exist or is not settable`);
  }

  setMap[key]?.(value, options);
};

export const registerSetter = <Key extends PublicSettableProperty>(
  key: Key,
  setter: (
    value: PublicPropertiesState<Key>[Key],
    options?: SetOptions[Key],
  ) => void,
) => {
  setMap[key] = setter as typeof setMap[Key];
};

export default set;
