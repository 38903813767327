import { getTagConfig } from '../tagConfig';

const getConfigUrl = (): string => {
  const { platformDomain } = getTagConfig();
  return `https://${platformDomain}.iadvize.com/`;
};

export const buildHttpUrl = (url: string) => `${getConfigUrl()}${url}`;

export const buildWsUrl = (url: string) =>
  getConfigUrl().replace(/^https?/, 'wss') + url;
