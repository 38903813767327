import {
  PublicProperty,
  PublicMethodsCallbacks,
  PublicGettableProperty,
} from '../../../shared/types/publicMethods';
import { publicGettableProperties } from '../publicProperties';
import throwPublicError from '../throwPublicError';

const getMap: Partial<
  Record<PublicProperty, PublicMethodsCallbacks[PublicProperty]>
> = {};

const get = <Key extends PublicProperty>(key: Key) => {
  if (!publicGettableProperties.includes(key as PublicGettableProperty)) {
    throwPublicError(`The "${key}" property does not exist`);
  }
  return getMap[key]?.() as ReturnType<PublicMethodsCallbacks[Key]>;
};

export const registerGetter = <Key extends PublicProperty>(
  key: Key,
  getter: PublicMethodsCallbacks[Key],
) => {
  getMap[key] = getter;
};

export const unregisterGetters = () => {
  Object.keys(getMap).forEach((key) => {
    delete getMap[key as PublicProperty];
  });
};

export default get;
