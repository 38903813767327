const IDZ_CONVERSATION_ID_QUERY_PARAM = 'idzconvid';

export const getConvidFromUrlQuery = (query = '') => {
  const searchParams = new URLSearchParams(query);
  return searchParams.get(IDZ_CONVERSATION_ID_QUERY_PARAM);
};

export function removeConversationIdParam() {
  const url = new URL(window.location.href);
  const convId = url.searchParams.get(IDZ_CONVERSATION_ID_QUERY_PARAM);
  if (!convId) {
    return;
  }
  url.searchParams.delete(IDZ_CONVERSATION_ID_QUERY_PARAM);
  window.history.pushState({}, '', url);
}

export default () => getConvidFromUrlQuery(document.location.search);
