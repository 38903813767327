export const delCookie = (name: string, domain?: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  //__________________________________________________
  if (parts.length > 1) {
    let cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; sameSite=None; Secure;`;
    if (domain) cookie += `domain=${domain};`;
    document.cookie = cookie;
  }
};
