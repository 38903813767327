export default function debounce<T extends unknown[], U>(
  callback: (...args: T) => PromiseLike<U> | U,
  delay: number,
  options?: { leading: boolean },
) {
  let timer: number;

  return (...args: T): Promise<U> => {
    if (options?.leading && !timer) {
      timer = Infinity;
      return Promise.resolve(callback(...args));
    }
    clearTimeout(timer);
    return new Promise((resolve) => {
      timer = window.setTimeout(() => resolve(callback(...args)), delay);
    });
  };
}
