import { ClickedUrlHandler } from '../handleClickedUrl/types';

export const getHandleClickedUrlModule = () =>
  import(
    /*____________________________________________*/ '../handleClickedUrl/handleClickedUrl'
  );

export const getHandleAddToCartModule = () =>
  import(
    /*___________________________________________*/ '../handleAddToCart/handleAddToCart'
  );

export default function labs() {
  return {
    handleClickedUrl: (clickedUrlHandler: ClickedUrlHandler) =>
      getHandleClickedUrlModule().then(({ default: handleClickedUrl }) =>
        handleClickedUrl(clickedUrlHandler),
      ),
    onAddToCart: (
      handler: (sku: string) => Promise<void | boolean> | undefined,
    ) =>
      getHandleAddToCartModule().then(({ onAddToCart }) =>
        onAddToCart(handler),
      ),
  };
}
