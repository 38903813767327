export const publicSettableProperties = [
  'visitor:cookiesConsent',
  'visitor:GDPRConsent',
] as const;

export const publicGettableProperties = [
  'app:boundaries',
  'chatbox:status',
  'conversation:id',
  'tag:version',
  'visitor:cookiesConsent',
  'visitor:GDPRConsent',
  'visitor:sourceId',
  'engagementRules:triggered',
  'engagementNotifications:displayed',
] as const;

export const publicOnlyListenableProperties = [
  'engagementRule:triggered',
  'engagementNotification:displayed',
  'engagementNotification:clicked',
] as const;

export const publicListenableProperties = [
  ...publicGettableProperties,
  ...publicOnlyListenableProperties,
] as const;

const publicProperties = [
  ...new Set([
    ...publicSettableProperties,
    ...publicGettableProperties,
    ...publicListenableProperties,
  ]),
] as const;

export default publicProperties;
