import { getPublicPropertiesStore } from './publicMethods/publicProperties/registerPublicProperties';
import { LaunchFullModeParams } from './types';

let isFullModeLaunched = false;

export const launchFullMode = ({
  staticConfig,
  vuid,
  commonConfig,
  screen,
  previousScreen,
  conversationId,
}: LaunchFullModeParams) => {
  if (isFullModeLaunched) {
    return;
  }
  isFullModeLaunched = true;
  const transactionCalls = window.iAdvize.recordTransaction?.calls;
  //_____________________________________
  const translationsFromTemplates = window.iAdvize.trad;
  import(/*_____________________________________*/ '../live/src/launcher')
    .then((m) =>
      m.default({
        staticConfig,
        vuid,
        commonConfig,
        screen,
        previousScreen,
        transactionCalls,
        translationsFromTemplates,
        conversationId,
      }),
    )
    .then(() => {
      getPublicPropertiesStore().dispatch('tag:version', 'FULL');
    });
};
