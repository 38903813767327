import { v4 as uuid } from 'uuid';
import getTopDomain from '../storage/cookieStorage/getTopDomain';
import { getCookie } from '../storage/cookieStorage/getCookie';
import { setCookie } from '../storage/cookieStorage/setCookie';
import {
  getDecodedCookieValue,
  getEncodedCookieValue,
} from '../storage/cookieStorage/utils';
import { EncodedCookieObject } from '../storage/cookieStorage/types';
import createState from '../shared/utils/createState';

type VisitorIdentity = {
  deviceId: string;
  vuid: string | null;
};

export const [getInMemoryVisitorIdentity, setInMemoryVisitorIdentity] =
  createState<VisitorIdentity>({
    deviceId: uuid(),
    vuid: null,
  });

export const getStoredVisitorIdentity = (sid: number): VisitorIdentity => {
  const visitorIdentity = getInMemoryVisitorIdentity();

  const cookie = getCookie(`iadvize-${sid}-vuid`);
  if (cookie !== null) {
    try {
      const parsedCookie = getDecodedCookieValue<
        | string
        | {
            vuid: string;
            deviceId: string;
          }
      >(cookie as EncodedCookieObject);
      if (typeof parsedCookie === 'string') {
        visitorIdentity.vuid = parsedCookie;
      } else {
        visitorIdentity.deviceId = parsedCookie.deviceId;
        visitorIdentity.vuid = parsedCookie.vuid;
      }
    } catch {
      visitorIdentity.vuid = cookie;
    }
  }

  return visitorIdentity;
};

export const setStoredVisitorIdentity = (
  sid: number,
  vuid: string,
  deviceId?: string,
  ttl?: number,
): void => {
  setCookie({
    domain: getTopDomain(),
    name: `iadvize-${sid}-vuid`,
    value: getEncodedCookieValue(
      deviceId ? JSON.stringify({ vuid, deviceId }) : vuid,
    ),
    ttl,
  });
};
