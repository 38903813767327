import { MatchedEngagementRule } from '../visitorContextConfig/types';
import createState from '../../shared/utils/createState';
import areArraysEqual from '../../shared/utils/areArraysEqual';

export const [
  getExecutedEngagementRules,
  setExecutedEngagementRules,
  onExecutedEngagementRulesChange,
] = createState<MatchedEngagementRule[]>(
  [],
  (r1: MatchedEngagementRule[], r2: MatchedEngagementRule[]) =>
    areArraysEqual(
      r1.map((r) => r.engagementRuleId),
      r2.map((r) => r.engagementRuleId),
    ),
);
