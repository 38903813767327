import { buildHttpUrl } from '../entry/buildUrl/getUrls';

let staticUrl: string | null = null;

export const setStaticUrl = (url?: string) => {
  const buildIdentifier = process.env.BUILD_IDENTIFIER;
  staticUrl =
    buildIdentifier?.includes(process.env.DEBUG_IP!) &&
    process.env.DEBUG_IP !== ''
      ? buildIdentifier
      : url || `${buildHttpUrl('static/livechat')}/${buildIdentifier}/`;
  return staticUrl;
};

export const getStaticUrl = () => staticUrl || setStaticUrl();
