import {
  DecodedCookieObject,
  DecodedCookieString,
  EncodedCookieValue,
} from './types';

export const encodeCookie = (cookie: string): EncodedCookieValue =>
  encodeURIComponent(cookie) as EncodedCookieValue;

export const decodeCookie = (encodedCookie: string): string =>
  decodeURIComponent(encodedCookie);

export const isJSONString = (maybeJSONCookie: string): boolean => {
  let result = String(maybeJSONCookie) !== 'null';
  try {
    JSON.parse(maybeJSONCookie);
  } catch {
    result = false;
  }
  return result;
};

export const getEncodedCookieValue = <
  T extends string | Record<string, unknown>,
>(
  decodedCookieValueOrObject: T,
): EncodedCookieValue => {
  if (typeof decodedCookieValueOrObject === 'string') {
    return encodeCookie(decodedCookieValueOrObject);
  }
  const stringifiedDecodedCookieObject = JSON.stringify(
    decodedCookieValueOrObject,
  );
  return encodeCookie(stringifiedDecodedCookieObject);
};

export const getDecodedCookieValue = <
  T extends string | Record<string, unknown> = {},
>(
  encodedCookieValue: EncodedCookieValue,
): T extends Record<string, unknown>
  ? DecodedCookieObject<T>
  : DecodedCookieString => {
  if (isJSONString(encodedCookieValue)) {
    return JSON.parse(encodedCookieValue);
  }
  const decodedCookieValue = decodeCookie(encodedCookieValue);
  return isJSONString(decodedCookieValue)
    ? JSON.parse(decodedCookieValue)
    : decodedCookieValue;
};
