import { DispatcherWithRegistry } from '../live/src/event-manager/dispatcherWithRegistry';
import { GlobalIdz } from './types/globalTypes';

let isFromMail = false;

export const getIdz = () => window.iAdvize as GlobalIdz;
export const getPlatform = () => getIdz()?.platform;
export const getWebsiteId = () =>
  parseInt(getIdz()?.website_id as unknown as string, 10);
export const getEventManager = () =>
  getIdz()?.require<DispatcherWithRegistry>('dispatcher');
export const getBuildHttpUrl = () => getIdz()?.urls.buildHttpUrl;

export const isChatting = () =>
  Number(getIdz()?.flags?.chat_status) === 2 || getIdz()?.chat?.status === 2;
export const wasChatting = () =>
  Number(getIdz()?.flags?.chat_status_witnessed) === 2;
export const getIsFromMail = () => isFromMail;
export const setIsFromMail = () => {
  isFromMail = document.location.search.indexOf('idzconvid') !== -1;
};
export const didVisitorMissConversationClosure = () =>
  wasChatting() && !isChatting();
export const shouldReengage = () =>
  (getIsFromMail() && !isChatting()) || didVisitorMissConversationClosure();
export const getIsContactOngoing = () =>
  (getIdz()?.chat && isChatting()) || shouldReengage();

export const getOngoingConversationType = ():
  | 'call'
  | 'chat'
  | 'video'
  | undefined => {
  const idz = getIdz();
  if (idz.on_call === 1 && isChatting()) {
    return 'call';
  }
  if (idz.flags?.visio_status === '1') {
    return 'video';
  }
  if (isChatting()) {
    return 'chat';
  }
  return undefined;
};
