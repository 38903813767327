import createState from '../../shared/utils/createState';
import { getTagConfig } from '../tagConfig';

export const [getIsGdprConsentEnabled, setIsGdprConsentEnabled] =
  createState(false);

export const [getIsCmpAppConfigured, setIsCmpAppConfigured] =
  createState(false);

export const getUseExplicitCookiesConsent = () =>
  getTagConfig().useExplicitCookiesConsent ||
  getIsGdprConsentEnabled() ||
  getIsCmpAppConfigured();
