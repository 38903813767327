import throttle from '../../shared/utils/throttle';
import Timer from '../../shared/utils/timer';

/*_________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/

const ACTIVE_TIME_TIMEOUT = 5000;

let activeTimeSpentOnPageTimeout: number | null = null;

export const activeTimeSpentOnPageTimer = new Timer();

/*__________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
const handleVisitorMovement = throttle(() => {
  activeTimeSpentOnPageTimer.start();
  if (activeTimeSpentOnPageTimeout) {
    window.clearTimeout(activeTimeSpentOnPageTimeout);
  }
  activeTimeSpentOnPageTimeout = window.setTimeout(() => {
    activeTimeSpentOnPageTimer.reset();
  }, ACTIVE_TIME_TIMEOUT);
}, ACTIVE_TIME_TIMEOUT / 2);

/*______________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
export const initActivityEventHandlers = () => {
  const visibilityHandler = () => {
    if (document.visibilityState === 'visible') {
      handleVisitorMovement();
    }
  };

  window.addEventListener('scroll', handleVisitorMovement);
  document.addEventListener('keypress', handleVisitorMovement);
  document.body.addEventListener('mousemove', handleVisitorMovement);
  document.addEventListener('visibilitychange', visibilityHandler);
};
