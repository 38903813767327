/*_______________________________*/
import debounce from '../../../shared/utils/debounce';
import {
  ErrorWithMessage,
  LoggerErrorEvent,
  LoggerEvent,
  LoggerInfoEvent,
  LoggerService,
} from './types';

let events: LoggerEvent[] = [];

export default (baseUrl: string, websiteId: number): LoggerService => {
  const isProduction = process.env.PRODUCTION;

  const sendEvents = (eventsToSend: LoggerEvent[]) => {
    if (events.length === 0) {
      return;
    }
    fetch(`${baseUrl}api/website/${websiteId}/log`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify(eventsToSend),
    }).catch(() => {
      //__________________
    });
  };

  const send = debounce(
    () => {
      sendEvents(events);
      events = [];
    },
    15000,
    {
      leading: false,
    },
  );

  function isErrorWithMessage(err: unknown): err is ErrorWithMessage {
    return (
      typeof err === 'object' &&
      err !== null &&
      'message' in err &&
      typeof (err as Record<string, unknown>).message === 'string'
    );
  }

  function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
    if (isErrorWithMessage(maybeError)) return maybeError;

    try {
      return new Error(JSON.stringify(maybeError));
    } catch {
      //__________________________________________________________________
      //_______________________________________________
      return new Error(String(maybeError));
    }
  }

  function getErrorMessage(err: unknown) {
    return toErrorWithMessage(err).message;
  }

  /*______________________________________*/
  function error(
    desc: string,
    err: unknown,
    msg: string,
    extras?: Record<string, unknown>,
  ) {
    if (!isProduction) {
      console.error(err, desc, msg, extras);
      return;
    }

    const errorEvent: LoggerErrorEvent = {
      desc,
      err: getErrorMessage(err),
      msg,
      extras,
    };

    events.push(errorEvent);

    send();
  }

  /*_____________________________________*/
  function info(desc: string, msg: string, extras?: Record<string, unknown>) {
    if (!isProduction) {
      console.log(desc, msg, extras);
      return;
    }
    const infoEvent: LoggerInfoEvent = {
      desc,
      msg,
      extras,
    };
    events.push(infoEvent);
    send();
  }

  return {
    error,
    info,
  };
};
