import {
  PublicGettableProperty,
  PublicListenableProperty,
  PublicOnEvents,
  PublicOnlyListenableProperty,
} from '../../../shared/types/publicMethods';
import getPublicProperty from '../getPublicEventFromKey';
import { getPublicPropertiesStore } from '../publicProperties/registerPublicProperties';

function on<Key extends PublicGettableProperty>(
  key: `${Key}Change`,
  callback: PublicOnEvents<Key>[Key],
): void;

function on<Key extends PublicOnlyListenableProperty>(
  key: Key,
  callback: PublicOnEvents<Key>[Key],
): void;

function on<Key extends PublicListenableProperty>(
  key: Key,
  callback: PublicOnEvents<Key>[Key],
): void {
  const property = getPublicProperty(key, callback);
  getPublicPropertiesStore().on(property, callback as PublicOnEvents[Key]);
}

export default on;
