import { CustomDataValues } from './types';

export const getJSCustomData = () =>
  window.idzCustomData
    ? Object.keys(window.idzCustomData).reduce<CustomDataValues>((acc, key) => {
        const val = window.idzCustomData![key];
        if (['number', 'string', 'boolean'].includes(typeof val)) {
          return { ...acc, [key]: val };
        }
        if (Array.isArray(val)) {
          return { ...acc, [key]: val.join() };
        }
        return acc;
      }, {})
    : {};
