import { getScriptLink } from './getScriptLink';

/*_____________________________________________*/
export const getPlatformDomain = (): string => {
  const link = getScriptLink();
  if (!link) {
    return 'halc';
  }
  const start = link.indexOf('//') + 2;
  const end = link.indexOf('.iadvize.com');
  return link.slice(start, end);
};

export const getPlatform = (): string => {
  const platformDomain = getPlatformDomain();
  if (platformDomain.includes('livechat-')) {
    return platformDomain.replace('livechat-', '');
  }
  return platformDomain === 'lc' ? 'sd' : 'ha';
};
