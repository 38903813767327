/*_______________________________________________________*/
import { IAdvizeCallbacks } from '../../shared/types/callbacks';
import createState from '../../shared/utils/createState';
import get from '../publicMethods/get/get';
import { getTagConfig } from '../tagConfig';
import {
  AfterInterfaceV1Loaded,
  AfterInterfaceV2Loaded,
  BeforeInterfaceV1Loaded,
  BeforeInterfaceV2Loaded,
  IAdvizeInterface,
  IAdvizeInterfaceV2,
  InterfaceV2Method,
  PartialPublicFunctions,
  PublicMethod,
} from './types';

export const [getPublicMethods, setPublicMethods] = createState<
  readonly PublicMethod[]
>([
  'get',
  'help',
  'labs',
  'navigate',
  'off',
  'on',
  'recordTransaction',
  'set',
  //_______________________________________________________________________________________
  'setVisitorCookiesConsent',
] as const);

const getIdzInterface = () => window.iAdvize.idzInterface;
const getMethods = <T extends string = PublicMethod>(): Record<
  T,
  (...args: any[]) => void
> => getIdzInterface().methods;
const getCallBuffer = () => getIdzInterface().callBuffer;

/*_________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/

const getIsInterfaceV2 = (
  iAdvizeInterface: IAdvizeInterface,
): iAdvizeInterface is IAdvizeInterfaceV2 => Array.isArray(iAdvizeInterface);

/*__________________________________________________________________________________________________________________________________________________________________________*/
export const initIdzInterface = () => {
  if (getIdzInterface()) {
    throw new Error('idz interface can only be instanced once');
  }

  const beforeTagLoadedInterface = window.iAdvizeInterface as
    | BeforeInterfaceV1Loaded
    | BeforeInterfaceV2Loaded;
  const isInterfaceV1 =
    beforeTagLoadedInterface instanceof Function &&
    beforeTagLoadedInterface.a instanceof Array;

  const isInterfaceV2 = getIsInterfaceV2(window.iAdvizeInterface);

  //_______________________________________________
  const callBuffer = isInterfaceV1
    ? beforeTagLoadedInterface.a
    : isInterfaceV2
    ? (window.iAdvizeInterface as IAdvizeInterfaceV2)
    : [];

  //_____________________________________________________________________________________________________
  //__________________________________________________________________________
  window.iAdvize.idzInterface = {
    callBuffer,
    methods: {},
  };

  //_______________________________________________________________________________________________________
  if (isInterfaceV1) {
    (window.iAdvizeInterface as AfterInterfaceV1Loaded) = (
      method: PublicMethod,
      ...args: any[]
    ) => {
      const methods = getMethods();
      if (methods[method] instanceof Function) {
        methods[method](...args);
      } else {
        getCallBuffer().push([method, ...args]);
      }
    };
  }
  window.iAdvizeInterface = window.iAdvizeInterface || [];
  (window.iAdvizeInterface as AfterInterfaceV2Loaded).push = (
    method: InterfaceV2Method,
  ) => {
    try {
      method(window.iAdvize);
    } catch {
      //________________________________________
      const indexes = Object.keys(window.iAdvizeInterface)
        .map(Number)
        .filter((n) => !Number.isNaN(n));
      const maxIndex = indexes.length ? Math.max(...indexes) : -1;
      (window.iAdvizeInterface as AfterInterfaceV2Loaded)[maxIndex + 1] =
        method;
    }
    //________________________________________________________
    return (window.iAdvizeInterface as AfterInterfaceV2Loaded).length;
  };
  (window.iAdvizeInterface as AfterInterfaceV2Loaded).config = getTagConfig();
};

const tryExecutingCallsInBuffer = () => {
  const methods = getMethods();
  const callBuffer = getCallBuffer();
  const { iAdvizeInterface } = window;
  const isInterfaceV2 = getIsInterfaceV2(iAdvizeInterface);

  const keptInCallBuffer = callBuffer.filter((command) => {
    if (isInterfaceV2) {
      try {
        command(window.iAdvize);
        return false;
      } catch {
        //_______________________________________
        return true;
      }
    } else {
      if (!(methods[command[0] as PublicMethod] instanceof Function)) {
        //_______________________________________
        return true;
      }
      const [method, ...args] = Object.values(command);
      methods[method as PublicMethod](...args);
      return false;
    }
  });

  getIdzInterface().callBuffer = keptInCallBuffer;
  if (isInterfaceV2) {
    const { config, push } = iAdvizeInterface;
    window.iAdvizeInterface = Object.assign(keptInCallBuffer, {
      config,
      push,
    });
  }
};

let trackingMethod: (method: PublicMethod) => void;

export const registerPublicMethodsTracking = (
  fn: (method: PublicMethod) => void,
) => {
  trackingMethod = fn;
};

type FunctionWithProps = {
  (...args: any[]): void;
  [key: string]: any;
};

export type CalledMethods = {
  sdk: PublicMethod[];
  callbacks: (keyof IAdvizeCallbacks)[];
};
export const [getInMemoryCalledMethods, setInMemoryCalledMethods] =
  createState<CalledMethods>({
    sdk: [],
    callbacks: [],
  });

const wrapWithTracking = (fn: FunctionWithProps, name: PublicMethod) => {
  const wrappedFn = <FunctionWithProps>function (...args: any[]) {
    const result = fn(...args);

    try {
      const { sid } = getTagConfig();
      const calledMethods = getInMemoryCalledMethods();

      if (get('visitor:cookiesConsent')) {
        const key = `iadvize-${sid}-calledMethods`;

        const sessionValue = sessionStorage.getItem(key);
        const alreadyCalledMethods: CalledMethods = sessionValue
          ? JSON.parse(sessionValue)
          : calledMethods;

        if (!alreadyCalledMethods.sdk.includes(name) && trackingMethod) {
          alreadyCalledMethods.sdk.push(name);
          sessionStorage.setItem(key, JSON.stringify(alreadyCalledMethods));
          trackingMethod?.(name);
        }
      } else if (!calledMethods.sdk.includes(name)) {
        calledMethods.sdk.push(name);
        setInMemoryCalledMethods(calledMethods);
      }
    } catch (e) {
      //__________________
    }

    return result;
  };

  Object.keys(fn).forEach((prop) => {
    wrappedFn[prop] = fn[prop];
  });

  return wrappedFn;
};

/*_______________________________________________________________________________________________________________________________________________________________________________________________________*/
export const registerPublicMethods = (
  newMethods: PartialPublicFunctions,
  requiredMethods: readonly string[] = getPublicMethods(),
) => {
  const wrappedMethods = Object.keys(newMethods).reduce<PartialPublicFunctions>(
    (acc, methodName) => {
      const name = methodName as PublicMethod;
      acc[name] = wrapWithTracking(newMethods[name]!, name);
      return acc;
    },
    {},
  );

  getIdzInterface().methods = {
    ...getMethods(),
    ...wrappedMethods,
  };

  Object.keys(wrappedMethods).forEach((method) => {
    //______________________________________________
    window.iAdvize[method] = wrappedMethods[method];
  });

  //______________________________________________
  if (
    requiredMethods.every((method) =>
      Object.keys(getIdzInterface().methods).includes(method),
    )
  ) {
    tryExecutingCallsInBuffer();
  }
};
